import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const AlbumCover = ({
  slug = "#",
  title = "Title Missing",
  fluid,
  className = "",
  index = 0,
}) => {
  const cardColors = [
    {
      buttonColor: "nofnecGreen",
      altColor: "white",
    },
    {
      buttonColor: "blue-500",
      altColor: "white",
    },

    {
      buttonColor: "red-600",
      altColor: "white",
    },
    {
      buttonColor: "gray-900",
      altColor: "white",
    },
  ]

  return (
    <Link
      className={`w-64 h-64 shadow-extra transition duration-200 ease-in-out transform hover:-translate-y-1 hover:scale-105 shadow-xl relative ${className}`}
      to={`/media/${slug}`}
      title={`${title} album`}
    >
      <div
        className={`absolute text-white top-0 left-0 z-10 w-full h-full flex justify-center items-center bg-${
          cardColors[index % 4].buttonColor
        } bg-opacity-50`}
      >
        <div className=" font-semibold text-3xl">{title}</div>
      </div>
      <Img
        fluid={{
          ...fluid,
          aspectRatio: 1 / 1,
        }}
        alt={title}
        className={``}
      />
    </Link>
  )
}

export default AlbumCover
