import { graphql, Link } from "gatsby"
import React from "react"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import Img from "gatsby-image"

import { sanityConfig } from "../sanityConfig"
import Layout from "../components/layout"
import BlockContentPrimary from "../components/BlockContentPrimary"
import { ButtonLinkInt } from "../components/Button"
import Downloads from "../components/Downloads"
import {
  CenteredContainer,
  H2,
  PageContainer,
  PageTitle,
  SubTitle,
} from "../components/SiteComponents"
import NewRenderWorksops from "../components/workshops/newRenderWorksops"
import AlbumCover from "../components/photoAlbum/AlbumCover"
import VideoPlayer from "../components/VideoPlayer"

// Data is passed from a GROQ query in gatsby-node.js in pageContext
const PastEventTemplate = ({ data, pageContext }) => {
  const {
    title,
    _rawMainContent: rawMainContent,
    allFiles,
    videoLink,
  } = data.sanityPastEvent
  // const { workshops: workhopsNEW } = pageContext.eventData
  const {
    albumIdData,
    eventData: { workshops },
  } = pageContext

  const coverImageId =
    albumIdData?.album?.albumCover?._id ||
    albumIdData?.album?.image?._id ||
    null

  // https://www.gatsbyjs.com/plugins/gatsby-source-sanity/#usage-outside-of-graphql
  const coverImageFluidProps = getFluidGatsbyImage(
    coverImageId,
    { maxWidth: 500 },
    sanityConfig
  )

  return (
    <Layout>
      <PageContainer className="mt-20 px-3 md:px-10">
        <PageTitle className="mb-10 text-nofnecGreen">{title}</PageTitle>

        {/* Event Description */}
        <div className=" max-w-screen-xl">
          {rawMainContent && <BlockContentPrimary blockData={rawMainContent} />}
        </div>

        {/* Event Video */}
        {videoLink && (
          <section className="my-20 w-full grid justify-items-center">
            <VideoPlayer url={videoLink} />
          </section>
        )}

        {/* <Link to={`/media/${albumIdData.slug.current}`} className="w-100">
          <Img fluid={coverImageFluidProps} />
        </Link> */}
        {coverImageId && (
          <div className="grid mb-16">
            <SubTitle className="mb-5">{title} Photos</SubTitle>

            <AlbumCover
              slug={albumIdData.slug.current}
              fluid={coverImageFluidProps}
              title={`${albumIdData.title}`}
              className="justify-self-center"
            />
          </div>
        )}

        <CenteredContainer className="grid gap-y-5">
          {workshops.length > 0 && (
            <SubTitle className=" leading-tight">{title} Workshops</SubTitle>
          )}

          <NewRenderWorksops workshops={workshops} />
        </CenteredContainer>
        <Downloads files={allFiles} />
        <ButtonLinkInt
          to="/past-events"
          text="All Past Events"
          className="  bg-nofnecGreen text-white hover:bg-green-200 hover:text-nofnecGreen text-2xl md:text-3xl mt-10 rounded-md px-6"
        />
      </PageContainer>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    sanityPastEvent(id: { eq: $id }) {
      title
      allFiles {
        title
        asset {
          url
          originalFilename
          title
        }
      }
      _rawMainContent(resolveReferences: { maxDepth: 10 })
      videoLink
    }
  }
`

export default PastEventTemplate
