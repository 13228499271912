import React from "react"
import ContentTile from "./contentTile"

const NewRenderWorksops = ({ workshops = ["1", "2", "3", "4"] }) => {
  const colors = [
    { theme: "blue" },
    { theme: "green" },
    { theme: "red" },
    { theme: "black" },
  ]
  return (
    <>
      {workshops.map((workshop, index) => {
        const {
          title,
          slug: { current: slug },
          summary,
          presenters,
          isVirtualEvent = false,
        } = workshop
        return (
          <div className="flex w-full justify-center mb-5">
            <ContentTile
              title={title}
              color={colors[index % 4].theme}
              showDate={false}
              showTime={false}
              slug={slug}
              text={summary}
              presenters={presenters}
              isVirtualEvent={isVirtualEvent}
              id={`newRenderWorkshops-${index}`}
            />
          </div>
        )
      })}
    </>
  )
}

export default NewRenderWorksops
